import { useAsObservableSource, useLocalStore } from 'mobx-react';
import {
  RefObject,
  useRef,
  useEffect,
  useLayoutEffect,
  KeyboardEvent,
  ChangeEvent,
  ClipboardEvent,
} from 'react';

import {
  ENTER_KEY_CODE,
  K_KEY_CODE,
  U_KEY_CODE,
  UP_ARROW_KEY_CODE,
  B_KEY_CODE,
  I_KEY_CODE,
} from 'APP/constants/keyCodes';
import { htmlConverter, textConverter } from 'APP/packages/copyPasteConverter';
import patterns from 'APP/packages/patterns';
import { platformInfo } from 'APP/packages/platformInfo/platformInfo';
import { User } from 'STORE/Users/User/User';
import { debounce } from 'UTILS/debounce';
import { removeAllHTMLTags } from 'UTILS/html/removeAllHTMLTags';
import { removeFontTags } from 'UTILS/html/removeFontTags';
import { replaceDivTags } from 'UTILS/html/replaceDivTags';

import {
  ITextFormattingInputPresenter,
  ITextFormattingInputParams,
} from './TextFormattingInput.types';

interface IUseTextFormattingInputPresenter {
  presenter: ITextFormattingInputPresenter;
  inputRef: RefObject<HTMLDivElement>;
}

const CHANGE_MENTION_DELAY = 500;

const isCtrlKeyPressed = (event: KeyboardEvent<HTMLDivElement>): boolean => {
  return event.ctrlKey || event.metaKey;
};

const stopEvent = (event: KeyboardEvent): void => {
  event.preventDefault();
  event.stopPropagation();
};

export const useTextFormattingInputPresenter = (
  data: ITextFormattingInputParams
): IUseTextFormattingInputPresenter => {
  const {
    inputPresenterRef,
    value,
    placeholder,
    isAutoFocus = true,
    onChange,
    onSubmit,
    onSubmitFiles,
    onEdit,
    onSelectMention,
  } = data;

  const inputRef = useRef<HTMLDivElement>(null);
  const source = useAsObservableSource({
    placeholder,
    onChange,
    onSubmit,
    onSubmitFiles,
    onEdit,
    onSelectMention,
  });

  const presenter = useLocalStore<ITextFormattingInputPresenter>(() => ({
    value: '',
    selectionRange: null,
    selectiedMention: null,
    isShowMenu: false,

    get isEmpty(): boolean {
      return removeAllHTMLTags(presenter.value).length === 0;
    },

    get isShowPlaceholder(): boolean {
      return Boolean(source.placeholder) && presenter.isEmpty;
    },

    get placeholder(): string {
      return source.placeholder || '';
    },

    setValue(value: string): void {
      if (presenter.value !== value) {
        presenter.value = value;
        inputRef.current!.innerHTML = value;
        presenter.selectionRange = null;
        presenter.setFocus();
      }
    },

    setSelection(range: Range | null): void {
      const selection = window.getSelection();
      if (!selection) {
        return;
      }

      selection.removeAllRanges();
      if (range) {
        selection.addRange(range);
      }
    },

    setSelectionToEnd(): void {
      const range: Range = document.createRange();

      if (inputRef.current!.hasChildNodes()) {
        range.selectNodeContents(inputRef.current!.lastChild!);
        range.collapse(false);
      } else {
        range.setStart(inputRef.current!, 0);
        range.setEnd(inputRef.current!, 0);
        range.collapse(false);
      }

      presenter.setSelection(range);
    },

    setFocus(): void {
      if (presenter.selectionRange) {
        presenter.setSelection(presenter.selectionRange);
      } else {
        presenter.setSelectionToEnd();
      }

      inputRef.current!.focus();
    },

    findMention(): string | null {
      if (!presenter.selectionRange || !presenter.selectionRange.collapsed) {
        return null;
      }

      let selectedStr = presenter.selectionRange.commonAncestorContainer.nodeValue || '';
      selectedStr = selectedStr.substring(0, presenter.selectionRange.startOffset);
      selectedStr = selectedStr.split(' ').pop()!;

      if (selectedStr.length === 0) {
        return null;
      }

      if (selectedStr === '@') {
        return selectedStr;
      }

      const mentions = selectedStr.match(new RegExp(patterns.MENTION, 'ig')) || [];
      return mentions.pop() || null;
    },

    onInputChange(event: ChangeEvent<HTMLDivElement>): void {
      let html = replaceDivTags(removeFontTags(event.target.innerHTML));
      if (html === '<br>' || html === '\n') {
        event.target.innerHTML = '';
        html = '';
      }

      presenter.value = html;
      source.onChange(html);
    },

    onInputPaste(event: ClipboardEvent<HTMLDivElement>): void {
      event.preventDefault();

      const html = htmlConverter(event.clipboardData.getData('text/html'), { ignoreImage: true });
      if (html) {
        presenter.onPutHtml(html);
        return;
      }

      const text = textConverter(event.clipboardData.getData('text/plain') || '');
      if (text) {
        presenter.onPutText(text);
        return;
      }

      if (event.clipboardData.files?.length > 0 && source.onSubmitFiles) {
        presenter.isShowMenu = false;
        source.onSubmitFiles([...event.clipboardData.files]);
      }
    },

    onSelectText(): void {
      const selection = window.getSelection();
      presenter.isShowMenu = Boolean(selection && !selection.isCollapsed);
      presenter.selectionRange =
        selection && selection.rangeCount > 0 ? selection.getRangeAt(0) : null;

      presenter.onChangeMention();
    },

    onKeyDown(event: KeyboardEvent<HTMLDivElement>): void {
      if (event.keyCode === ENTER_KEY_CODE && event.shiftKey === false && source.onSubmit) {
        stopEvent(event);
        presenter.isShowMenu = false;
        source.onSubmit(presenter.value);
        return;
      }

      if (event.keyCode === UP_ARROW_KEY_CODE && presenter.isEmpty && source.onEdit) {
        stopEvent(event);

        // TODO review input logic for avoiding remove ranges explicitly
        if (platformInfo.isSafari) {
          const selection = window.getSelection();
          selection!.removeAllRanges();
        }

        presenter.isShowMenu = false;
        source.onEdit(presenter.value);
        return;
      }

      if (event.keyCode === B_KEY_CODE && isCtrlKeyPressed(event)) {
        stopEvent(event);
        presenter.onBold();
        return;
      }

      if (event.keyCode === I_KEY_CODE && isCtrlKeyPressed(event)) {
        stopEvent(event);
        presenter.onItalic();
        return;
      }

      if (event.keyCode === U_KEY_CODE && isCtrlKeyPressed(event)) {
        stopEvent(event);
        presenter.onUnderline();
        return;
      }

      if (event.keyCode === K_KEY_CODE && isCtrlKeyPressed(event)) {
        stopEvent(event);
        presenter.onStrikeThrough();
        return;
      }
    },

    onClick(event: Event): void {
      if (presenter.isShowMenu) {
        event.stopPropagation();
      }
    },

    onCloseMenu(): void {
      presenter.isShowMenu = false;
    },

    onBold(): void {
      presenter.setFocus();
      document.execCommand('bold', false);
      presenter.onSelectText();
    },

    onItalic(): void {
      presenter.setFocus();
      document.execCommand('italic', false);
      presenter.onSelectText();
    },

    onUnderline(): void {
      presenter.setFocus();
      document.execCommand('underline', false);
      presenter.onSelectText();
    },

    onStrikeThrough(): void {
      presenter.setFocus();
      document.execCommand('strikeThrough', false);
      presenter.onSelectText();
    },

    onAddLink(url: string): void {
      presenter.setFocus();
      document.execCommand('createLink', false, url);
      presenter.onSelectText();
    },

    onRemoveLink(): void {
      presenter.setFocus();
      document.execCommand('unlink', false);
      presenter.onSelectText();
    },

    onPutText(text: string): void {
      presenter.setFocus();
      document.execCommand('insertText', false, text);
      presenter.onSelectText();
    },

    onPutHtml(html: string): void {
      presenter.setFocus();
      document.execCommand('insertHTML', false, html);
      presenter.onSelectText();
    },

    onPutMention(user: User): void {
      if (!source.onSelectMention || !presenter.selectionRange) {
        return;
      }

      const selectedMention = presenter.findMention();
      if (!selectedMention) {
        return;
      }

      const container = presenter.selectionRange.commonAncestorContainer;
      const range: Range = document.createRange();
      range.setStart(container, presenter.selectionRange.endOffset - selectedMention.length + 1);
      range.setEnd(container, presenter.selectionRange.endOffset);

      presenter.setSelection(range);
      document.execCommand('insertText', false, `${user.nickName} `);
    },

    onChangeMention: debounce((): void => {
      if (source.onSelectMention) {
        source.onSelectMention(presenter.findMention());
      }
    }, CHANGE_MENTION_DELAY),
  }));

  useLayoutEffect(() => {
    if (!inputPresenterRef) {
      return;
    }

    inputPresenterRef.current = presenter;

    return () => {
      inputPresenterRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (isAutoFocus) {
      presenter.setFocus();
    }

    // This hack is need to open formatting menu on double click.
    // The ClickOutside listen second click and auto closing menu.
    inputRef.current!.addEventListener('click', presenter.onClick);

    return () => {
      inputRef.current!.removeEventListener('click', presenter.onClick);
    };
  }, []);

  useEffect(() => {
    presenter.setValue(value);
  }, [value]);

  return { presenter, inputRef };
};
