import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { MutableRefObject, useEffect, useRef } from 'react';

import Tasks from 'APP/Tasks';
import {
  ITextFormattingInputPresenter,
  TChangeHandler,
  TEditHandler,
  TSelectMentionHandler,
  TSubmitFilesHandler,
  TSubmitHandler,
} from 'APP/components/TextFormattingInput/TextFormattingInput.types';
import { useDraftInput } from 'APP/hooks/useDraftInput/useDraftInput';
import { useUserTyping } from 'APP/hooks/useUserTyping/useUserTyping';
import bus from 'APP/packages/bus';
import { parseToHtml } from 'APP/packages/markdown/parseToHtml';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { Schedule } from 'STORE/Groups/Schedule';
import InputPanel from 'STORE/InputPanelsStore/InputPanel';
import { encodeHtml } from 'UTILS/html/encodeHtml';

import { searchMentions } from './Tasks/searchMentions';

interface IInputSectionPresenter {
  inputPanel: InputPanel | null;
  group: Group | null;
  isScheduleGroup: boolean;
  isShowCommandsButton: boolean;
  isShowScheduleButton: boolean;
  value: string;
  onUpdateDraftInput(): void;
  onChangeValue: TChangeHandler;
  onSubmitValue: TSubmitHandler;
  onSubmitFiles: TSubmitFilesHandler;
  onEditLastMessage: TEditHandler;
  onSelectMention: TSelectMentionHandler;
}

interface IUseInputSectionPresenter {
  presenter: IInputSectionPresenter;
  inputRef: MutableRefObject<ITextFormattingInputPresenter | null>;
}

export const useInputSectionPresenter = (): IUseInputSectionPresenter => {
  const navigateTo = useNavigateTo();
  const userTyping = useUserTyping(Entities.GroupsStore.activeGroupId || '');
  const draftInput = useDraftInput(Entities.GroupsStore.activeGroupId || '');
  const inputRef = useRef<ITextFormattingInputPresenter | null>(null);

  const source = useAsObservableSource({ navigateTo });

  const presenter = useLocalStore<IInputSectionPresenter>(() => ({
    get inputPanel(): InputPanel | null {
      return Entities.InputPanelsStore.activeInputPanel || null;
    },

    get group(): Group | null {
      return Entities.GroupsStore.activeGroup;
    },

    get isScheduleGroup(): boolean {
      return presenter.group instanceof Schedule;
    },

    get isShowCommandsButton(): boolean {
      if (!presenter.inputPanel) {
        return false;
      }

      return presenter.inputPanel.bots.hasCommands;
    },

    get isShowScheduleButton(): boolean {
      if (presenter.group?.canBeScheduled) {
        const schGroup = Entities.GroupsStore.getGroupById(presenter.group.scheduleGroupId);
        return Boolean(schGroup?.messagesStore?.messages.length || -1 > 0);
      }

      return false;
    },

    get value(): string {
      return presenter.inputPanel?.value || '';
    },

    onChangeValue(value: string): void {
      Entities.InputPanel.setValue(value);

      if (!presenter.isScheduleGroup && !presenter.inputPanel?.isEditMode) {
        userTyping.send();
      }
    },

    onSubmitValue(): void {
      if (!presenter.value) {
        return;
      }

      if (presenter.isScheduleGroup) {
        if (presenter.inputPanel?.isEditMode) {
          Entities.InputPanel.send.schedule();
        } else {
          Tasks.group.goToScheduleSettings({ navigateTo: source.navigateTo });
        }
        return;
      }

      Entities.InputPanel.send.submit();
    },

    onSubmitFiles(files: File[]): void {
      Tasks.messaging.sendFiles({
        groupId: presenter.group!.id,
        files,
      });
    },

    onEditLastMessage(): void {
      if (!presenter.group || !Entities.InputPanel.edit.isAvailableStartEdit) {
        return;
      }

      const message = Tasks.messaging.getLastEditableMessage(presenter.group.id);
      if (!message) {
        return;
      }

      Tasks.messaging.startEditMessage({
        groupId: presenter.group!.id,
        editMessageId: message.id,
      });
    },

    onSelectMention(mention: string | null): void {
      if (!mention) {
        Tasks.search.resetMentions({ inputPanel: presenter.inputPanel! });
        return;
      }

      searchMentions(presenter.inputPanel!, mention.substring(1));
    },

    onUpdateDraftInput(): void {
      if (
        !presenter.group?.draftInput?.text ||
        !presenter.inputPanel ||
        presenter.inputPanel.isEditMode
      ) {
        return;
      }

      const text = encodeHtml(presenter.group.draftInput.text);
      const html = parseToHtml(text);

      presenter.inputPanel.setValue(html);
    },
  }));

  useEffect(() => {
    const reactions = [
      bus.messaging.subscribeSelectEmoji(inputRef.current!.onPutText),
      bus.messaging.subscribeStartQuoteMessage(inputRef.current!.setFocus),
      bus.messaging.subscribeSelectMention(inputRef.current!.onPutMention),
    ];

    return () => {
      reactions.forEach((dispose) => dispose());
    };
  }, []);

  useEffect(() => {
    //this value changed after send message with button
    draftInput.update(presenter.inputPanel?.value || '');
  }, [presenter.inputPanel?.value]);

  useEffect(() => {
    inputRef.current!.setFocus();
    presenter.onUpdateDraftInput();
  }, [presenter.group]);

  useEffect(() => {
    if (Entities.App.uiLayer === 0) {
      inputRef.current!.setFocus();
    }
  }, [Entities.App.uiLayer]);

  useEffect(() => {
    if (presenter.group?.canBeScheduled) {
      Tasks.group.loadScheduledMessages(presenter.group.id);
    }
  }, [presenter.group?.id]);

  return { presenter, inputRef };
};
