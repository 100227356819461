import Tasks from 'APP/Tasks';
import Entities from 'APP/store';

export default ({ groupId, messages = [] }) => {
  const group = Entities.GroupsStore.getGroupById(groupId);
  if (!group) {
    return;
  }

  const scheduledGroupId = group.scheduleGroupId;
  const userId = Entities.UsersStore.Me.id;

  Tasks.messaging.updateOldOrFakeMessages({
    groupId: scheduledGroupId,
    messages: messages.map((msg) => ({
      ...msg,
      senderId: userId,
      groupId: scheduledGroupId,
      clientTime: parseInt(msg.clientTime) + parseInt(msg.delay),
      serverTime: parseInt(msg.serverTime) + parseInt(msg.delay),
    })),
  });

  const scheduleGroup = Entities.GroupsStore.getGroupById(scheduledGroupId);
  scheduleGroup.messagesStore.resetExpectedServerTime();
};
