import Tasks from 'APP/Tasks';
import { RecordingType } from 'APP/constants/calls';
import { ServerErrorCode } from 'APP/model/error/error.constants';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';
import { t } from 'MODULE/i18n/service';

interface IStartRecordingData {
  title: string;
  recordingType: RecordingType;
}

export const startRecording = async ({
  recordingType,
  title,
}: IStartRecordingData): Promise<void> => {
  try {
    logger.get('API').debug('calls.startRecording', { recordingType, title });
    if (!Entities.Calls.ongoingCall) {
      return;
    }
    const call = Entities.Calls.ongoingCall;
    const channelId = call.channelId;

    const { recordingId } = await api.calls.startRecording({
      channelId,
      recordingType,
      title: title || undefined,
    });

    if (call.me.isSharedScreen && channelId) {
      await Tasks.calls.sendStartScreenSharingEvent({
        channelId,
        recordingId,
      });
    }
  } catch (e) {
    logger.get('API').error('calls.startRecording', e);

    if (e.message === ServerErrorCode.RecordingIsStartedAlready) {
      Tasks.app.showAlert(t('call_record_started_already_message'));

      return;
    }

    if (e.message === ServerErrorCode.NoPermissionsToRecordACall) {
      Tasks.app.showAlert(t('call_record_no_permissions_to_start_message'));
      return;
    }

    if (e.message === ServerErrorCode.ChannelClosed) {
      Tasks.app.showAlert(t('call_record_completed_call_message'));

      return;
    }

    Tasks.app.showAlert(t('common_somethings_wrong_error'));
  }
};
