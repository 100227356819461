import { computed } from 'mobx';

import { Group } from '../Group';

export class Channel extends Group {
  get isChannel(): boolean {
    return true;
  }

  @computed
  get canInviteUsers(): boolean {
    return this.withMeInAdmins;
  }

  get canMentionAll(): boolean {
    return false;
  }

  get canBeScheduled(): boolean {
    return true;
  }
}
