import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { MAX_ITEMS_PER_ROW, MAX_ROW_COUNT } from 'APP/packages/album';
import { parseToHtml } from 'APP/packages/markdown/parseToHtml';
import { useTranslation } from 'APP/packages/translations';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import Entities from 'APP/store';
import { Group } from 'STORE/Groups/Group';
import { Schedule } from 'STORE/Groups/Schedule';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import FilePayload from 'STORE/Messages/Message/Payload/File';
import ImagePayload from 'STORE/Messages/Message/Payload/Image';
import VideoPayload from 'STORE/Messages/Message/Payload/Video';
import { encodeHtml } from 'UTILS/html/encodeHtml';
import { replaceHtmlTagsToSpecialSymbols } from 'UTILS/html/replaceHtmlTagsToSpecialSymbols';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { ILocalMessageType, IMediaMessagePayload, IRawMessage } from './MediaMessagePopup.types';
import { getFilePayload, isMediaPayload } from './MediaMessagePopup.utils';
import { editMediaMessage } from './Tasks/editMediaMessage';
import { sendMediaMessages } from './Tasks/sendMediaMessages';

interface IMediaMessagePopupPresenter {
  group: Group | null;
  payloads: IMediaMessagePayload[];
  isLoading: boolean;
  isEditMode: boolean;
  isShowSubmitMenu: boolean;
  mustBeScheduled: boolean;
  caption: string;
  editMessage: ChatMessage | null;
  rawMessages: IRawMessage[];
  popupTitle: string;
  canBeRemove: boolean;
  successTitle: string;
  isSendMediaAsFile: boolean;
  isRecalculateProcess: boolean;
  isShowSendMediaAsFile: boolean;
  init(caption: string): void;
  startLoading(): void;
  onSubmit(isScheduled?: boolean): Promise<void>;
  onInputSubmit(): void;
  setPayloads(payloads: IMediaMessagePayload[]): void;
  stopLoading(): void;
  onClose(isSaveValue?: boolean): void;
  remove(payload: IMediaMessagePayload): void;
  onChange(value: string): void;
  onChangeSendAsFile(value: boolean): void;
  reCalcPayloadsAsFiles(): Promise<void>;
}

const ALBUM_MAX_SIZE = MAX_ROW_COUNT * MAX_ITEMS_PER_ROW;

export interface IMediaMessagePopupPresenterParams {
  files: File[];
  caption: string;
  groupId: string;
  messageId?: string;
}

export function useMediaMessagePopupPresenter({
  params,
  popupInstance,
}: ICommonPopupsProps<IMediaMessagePopupPresenterParams>): IMediaMessagePopupPresenter {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const { caption, files = [], messageId, groupId } = params!;

  const presenter = useLocalStore<IMediaMessagePopupPresenter>(() => ({
    isEditMode: false,
    isLoading: false,
    caption: '',
    payloads: [],
    isSendMediaAsFile: false,
    isRecalculateProcess: false,

    init(caption: string): void {
      presenter.caption = parseToHtml(encodeHtml(caption));
    },

    get group(): Group | null {
      return Entities.GroupsStore.getGroupById(groupId);
    },

    startLoading(): void {
      presenter.isLoading = true;
    },

    stopLoading(): void {
      presenter.isLoading = false;
    },

    get mustBeScheduled(): boolean {
      return presenter.group instanceof Schedule;
    },

    get successTitle(): string {
      if (presenter.isEditMode) {
        return t('done');
      } else if (presenter.mustBeScheduled) {
        return t('common:common_schedule');
      }

      return t('send');
    },

    get popupTitle(): string {
      if (presenter.isEditMode) {
        return t('message_edit');
      }

      if (presenter.payloads.every(isMediaPayload)) {
        return t('media_popup_header', { 0: presenter.payloads.length });
      }
      return t('files_popup_header', { 0: presenter.payloads.length });
    },

    get isShowSubmitMenu(): boolean {
      return !presenter.isEditMode;
    },

    setPayloads(payloads: IMediaMessagePayload[]): void {
      presenter.payloads = payloads;
    },

    async onClose(isSaveValue = true): Promise<void> {
      if (presenter.isEditMode && presenter.editMessage) {
        const editText = presenter.editMessage.payload.comment || '';
        const caption = replaceHtmlTagsToSpecialSymbols(presenter.caption)?.trim();
        if (caption !== editText) {
          const res = await Tasks.app.showConfirm({ text: t('common_discard_changes_alert') });
          if (!res) {
            return;
          }
        }
      }

      if (!presenter.isEditMode && isSaveValue) {
        Entities.InputPanel.setValue(presenter.caption);
      }

      popupInstance.close();
    },

    async onSubmit(isScheduled?: boolean): Promise<void> {
      const group =
        presenter.group instanceof Schedule ? presenter.group.parentGroup : presenter.group;

      if (!group?.canSendMessages) {
        Tasks.app.showToast(t('moderation_disable_send_message'));
        return;
      }

      let result = true;

      if (presenter.isEditMode) {
        if (!presenter.editMessage) {
          return;
        }

        result = await editMediaMessage(
          presenter.editMessage,
          presenter.payloads,
          replaceHtmlTagsToSpecialSymbols(presenter.caption).trim(),
          presenter.mustBeScheduled || !!isScheduled
        );
      } else {
        result = await sendMediaMessages(
          group.id,
          presenter.rawMessages,
          replaceHtmlTagsToSpecialSymbols(presenter.caption).trim(),
          navigateTo,
          presenter.mustBeScheduled || !!isScheduled,
          presenter.group?.inputPanel.quoteMessage
        );
      }

      if (result) {
        presenter.onClose(false);
      }
    },

    onInputSubmit(): void {
      presenter.onSubmit(false);
    },

    onChange(value: string): void {
      presenter.caption = value;
    },

    onChangeSendAsFile(value: boolean): void {
      presenter.isSendMediaAsFile = value;
    },

    get canBeRemove(): boolean {
      if (presenter.isEditMode) {
        return false;
      }
      return true;
    },

    get isShowSendMediaAsFile(): boolean {
      if (presenter.isEditMode) {
        return false;
      }

      return (
        presenter.isSendMediaAsFile || presenter.payloads.some((payload) => isMediaPayload(payload))
      );
    },

    remove(payload): void {
      presenter.payloads = presenter.payloads.filter((item) => item !== payload);

      if (presenter.payloads.length === 0) {
        presenter.onClose();
      }
    },

    get editMessage(): ChatMessage | null {
      return presenter.group && messageId
        ? presenter.group.messagesStore.getMessageById(messageId)
        : null;
    },

    get rawMessages(): IRawMessage[] {
      // return single message if editable mode
      if (presenter.isLoading) {
        return [];
      }

      if (presenter.isEditMode && presenter.editMessage) {
        return [
          {
            id: presenter.editMessage.id,
            type:
              presenter.editMessage.payload.payloadType === PayloadType.Album
                ? ILocalMessageType.Album
                : ILocalMessageType.Media,
            payloads: presenter.payloads,
          },
        ];
      }

      // Splitting files into messages.
      // Media files are grouped into albums and other files by separate messages
      const messages: IRawMessage[] = [];
      let isLastMedia = false;
      let lastIndex = -1;
      presenter.payloads.forEach((payload: IMediaMessagePayload) => {
        const mediaFile = payload.data.file && isMediaPayload(payload);

        if (!mediaFile || presenter.isSendMediaAsFile) {
          lastIndex++;
          isLastMedia = false;
          messages[lastIndex] = {
            id: lastIndex,
            type: ILocalMessageType.File,
            payloads: [payload],
          };
          return;
        }

        if (!payload.data.graphicSize) {
          lastIndex++;
          messages[lastIndex] = {
            id: lastIndex,
            type: ILocalMessageType.Media,
            payloads: [payload],
          };
          isLastMedia = false;
          return;
        }
        lastIndex = isLastMedia ? lastIndex : lastIndex + 1;
        isLastMedia = true;
        messages[lastIndex] = messages[lastIndex] || {
          id: lastIndex,
          type: ILocalMessageType.Media,
          payloads: [],
        };
        if (messages[lastIndex]?.payloads?.length < ALBUM_MAX_SIZE) {
          const length = messages[lastIndex].payloads.push(payload);
          messages[lastIndex].type = length > 1 ? ILocalMessageType.Album : ILocalMessageType.Media;
        } else {
          lastIndex++;
          messages[lastIndex] = {
            id: lastIndex,
            type: ILocalMessageType.Media,
            payloads: [payload],
          };
        }
      });
      return messages;
    },

    async reCalcPayloadsAsFiles(): Promise<void> {
      presenter.isRecalculateProcess = true;
      const payloadFiles = presenter.payloads
        .map((payload) => payload.data)
        .filter(({ file }) => !!file);
      const payloads = await Promise.all(
        payloadFiles.map(({ file, fileName }) =>
          getFilePayload(file!, fileName || '', presenter.isSendMediaAsFile)
        )
      );

      if (payloads.length) {
        presenter.setPayloads(payloads);
      }
      presenter.isRecalculateProcess = false;
    },
  }));

  useEffect(() => {
    presenter.init(caption);
  }, []);

  useEffect(() => {
    const getPayloads: () => Promise<void> = async () => {
      presenter.startLoading();
      if (messageId && files.length === 0) {
        const group = presenter.group;
        let message;
        if (group) {
          message = group.messagesStore.getMessageById(messageId);
          if (message) {
            presenter.init(message?.payload?.comment || '');
            presenter.isEditMode = true;

            const payloads = (message.payload.payloads || [message]).map((x: ChatMessage) => {
              return {
                id: x.id,
                type: x.payload.payloadType,
                data: {
                  duration: (x.payload as FilePayload).duration,
                  graphicSize: (x.payload as ImagePayload | VideoPayload).graphicSize,
                  fileSize: (x.payload as FilePayload).fileSize,
                  fileName: (x.payload as FilePayload).fileName,
                  localPreview:
                    (x.payload as ImagePayload | VideoPayload).previewUrl ||
                    (x.payload as ImagePayload | VideoPayload).url,
                },
              };
            });
            presenter.setPayloads(payloads);
          }
        }
        // close popup if no message found
        if (!message || !group) {
          presenter.onClose();
        }
      } else {
        const getPayload = async (): Promise<void> => {
          const payloads = await Promise.all(
            (files || []).map((file) => getFilePayload(file, file.name))
          );
          presenter.setPayloads(payloads);
        };
        await getPayload();
        presenter.isEditMode = false;
      }
      presenter.stopLoading();
    };
    getPayloads();
  }, [messageId, groupId, files, popupInstance, presenter]);

  useEffect(() => {
    presenter.reCalcPayloadsAsFiles();
  }, [presenter.isSendMediaAsFile]);

  useEffect(() => {
    if (presenter.isEditMode && !presenter.editMessage) {
      presenter.onClose();
    }
  }, [presenter.editMessage, presenter.isEditMode]);

  useEffect(() => {
    if (!Entities.GroupsStore.isLoading && !presenter.group) {
      popupInstance.close();
    }
  }, [presenter.group, Entities.GroupsStore.isLoading]);

  return presenter;
}
