import { runInAction } from 'mobx';

import Entities from 'APP/store';
import { MessageError } from 'STORE/Messages/Message/ChatMessage/ChatMessages.types';

const messageLocalStateFields = [
  'isLoadingTranslate',
  'isShowTranslate',
  'expectedServerTime',
  'optimisticReactions',
  'isLocalLast',
  'isLocalFirst',
  'hasNewMessagePlaceholder',
  'error',
];

/**
 *
 * @param messages list of messages without gaps
 * @param groupId
 * @param prevMessageTs
 * @param targetGroup - link to group for cases if group is not exists in store yet
 */
export function updateOldOrFakeMessages({
  messages,
  groupId,
  prevMessageTs = 0,
  targetGroup = null,
}) {
  runInAction(() => {
    const group = Entities.GroupsStore.getGroupById(groupId) || targetGroup;

    if (!group) {
      return;
    }
    const realMessages = messages.filter((message) => message.groupId === group.id);

    if (realMessages.length === 0) {
      return;
    }
    const tsList = realMessages
      .map((message) => {
        const oldMessage =
          group.messagesStore.getMessageById(message.id) ||
          group.messagesStore.getMessageById(message.clientUuid);

        group.messagesStore.removeMessageById(message.clientUuid);

        const newMessage = group.messagesStore.addMessage(message);
        // copy fields from only old message is fake or oldMessage.id === newMessage.id
        // case with several messages with the same uuid GEM-27183
        if (oldMessage?.isFake || oldMessage?.id === message.id) {
          const newMessage = group.messagesStore.getMessageById(message.id);
          messageLocalStateFields.forEach((field) => {
            newMessage[field] = oldMessage[field];
          });

          if (
            oldMessage.isFake &&
            !newMessage.isFake &&
            (oldMessage.error === MessageError.Forward || oldMessage.error === MessageError.Send)
          ) {
            newMessage.resetError();
          }

          if (oldMessage.editTime !== newMessage.editTime) {
            group.messagesStore.removeTranslation(oldMessage);
          }
        }

        const inputPanel = Entities.InputPanelsStore.getById(message.groupId);
        if (inputPanel && inputPanel.quoteMessage?.id === message.id) {
          inputPanel.setQuoteMessage(newMessage);
        }

        return [newMessage.serverTime, newMessage.expectedServerTime];
      })
      .flat();

    if (prevMessageTs) {
      tsList.push(prevMessageTs);
    }
    const startTs = Math.min(...tsList);
    const endTs = Math.max(...tsList);
    group.messagesStore.addBatch([startTs, endTs]);
  });
}
