import { observer } from 'mobx-react';
import React, { FC } from 'react';

import imgStab from 'APP/images/img-stub.png';
import { AsyncImage } from 'APP/packages/UiKit/AsyncImage/AsyncImage';
import PlayIcon from 'ICONS/ic-play-32.svg';

import { DeleteButton } from '../../components/DeleteButton/DeleteButton';
import { IItemMediaPresenter } from '../ItemMedia.presenter';
import styles from './MediaMessageVideo.styles.m.css';

interface IMediaMessageVideoProps {
  presenter: IItemMediaPresenter;
}

export const MediaMessageVideo: FC<IMediaMessageVideoProps> = observer(({ presenter }) => {
  return (
    <div className={styles.videoContainer}>
      {presenter.previewUrl && (
        <AsyncImage
          className={styles.video}
          src={presenter.previewUrl}
          alt={presenter.name}
        >
          <img
            className={styles.video}
            src={imgStab}
            alt={presenter.name}
          />
        </AsyncImage>
      )}
      <div className={styles.iconContainer}>
        <PlayIcon className={styles.icon} />
      </div>
      {presenter.canBeRemoved && (
        <DeleteButton
          onClick={presenter.remove}
          className={styles.delete}
        />
      )}
    </div>
  );
});
